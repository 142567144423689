<template>
<div class="travel-country-card border-2 border-sky-300 rounded-lg py-4 text-center cursor-pointer hover:bg-sky-300 hover:text-white">
  <slot></slot>
  <p class="mt-4 text-lg">{{ text }}</p>
</div>
</template>

<script>
export default {
  name: "CountryCard",
  props: {
    text: {
      type: String,
      default() {
        return 'USA'
      }
    }
  }
}
</script>

<style lang="scss">
.travel-country-card {
  position: relative;
  transition: 0.2s ease-in;
  &:hover {
    border: 2px solid #35b8f5;
  }
  p {
    font-family: 'Montserrat', sans-serif;
  }
  &:first-child, &:nth-child(2), &:nth-child(3) {
    &:before {
      content: '1';
      font-weight: 600;
      font-size: 20px;
      color: #fff;
      left: 10px;
      top: -9px;
      position: absolute;
      width: 48px;
      height: 48px;
      background-image: url("./../../assets/svg/icons8-bookmark.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 5px;
    }
  }
  &:nth-child(2) {
    &:before {
      content: '2';
      background-image: url("./../../assets/svg/icons8-bookmark-second.svg");
    }
  }
  &:nth-child(3) {
    &:before {
      content: '3';
      background-image: url("./../../assets/svg/icons8-bookmark-third.svg");
    }
  }
}

</style>