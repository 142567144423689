<template>
    <div class="mb-16 md:px-0">
        <p class="w-full text-3xl font-semibold mb-8 text-left text-black">
            {{ $t('order.discount') }}
        </p>

        <div class="w-full">
            <div class="w-full">
                <div class="  ">
                    <input-text
                        :label="$t('order.coupon')"
                        :placeholder="$t('order.coupon')"
                        v-model="couponCode"
                        @input="input"
                        size="coupon"
                    />
                </div>
                <template v-if="loading">
                    <div class="w-full flex justify-center items-center">
                        <spinner />
                    </div>
                </template>
                <template v-if="!loading">
                    <div
                        v-if="orderData.coupon_id"
                        class="flex items-center gap-2 px-4 py-2 border-2 border-sky-300 rounded-xl mb-4"
                    >
                        <p class="">Your discount:</p>

                        <span
                            class="font-medium text-xl text-sky-300"
                            v-html="couponDiscountAmount ? '$'+(couponDiscountAmount.reduce((partialSum, a) => partialSum + a, 0)) : '$0'"
                        ></span>
                    </div>
                </template>
                <purchase-rent-gift-card />

            </div>

            <div class="w-full relative">
                <div v-if="popupShow" class="max-w-xl text-sm bg-white p-4 rounded-lg absolute -top-56 left-36">
                    <p>
                        Insurance service is $2 per day.<br/>
                        1. Please contact us as soon as possible if the device is lost or stolen. <br/>
                        Insured Customer's Copayment: $50 <br/>
                        Non-Insured Customer: $300 <br/>
                        2. Any calls/data made by a third party during the rental period shall be paid for by the customer. <br/>
                        *Any cost of broken, lost or stolen device able to be covered by your insurance.
                    </p>
                </div>
                <h1 class="mb-2 text-gray-800">{{ $t('order.active_insurance') }}
                    <button
                        @mouseover="popupShow = true"
                        @mouseleave="popupShow = false"
                        @click="$router.push({name: 'Support'})"
                    >
                        ⓘ
                    </button>
                </h1>
                <div
                    :class="styleInsuranceButton"
                    @click="toggleInsurance"
                    v-if="this.selectedLang === 'en'"
                >
                    <p class="mr-4">Insurance:</p>
                    <h2>${{ orderData.insurance_amount }} Per day</h2>
                </div>
                <div
                    :class="styleInsuranceButton"
                    @click="toggleInsurance"
                    v-if="this.selectedLang === 'jp'"
                >
                    <p class="mr-4">1日あたり</p>
                    <h2>${{ orderData.insurance_amount }}</h2>
                </div>
                <div
                    :class="styleCancelButton"
                    class="my-4"
                    @click="toggleCheckbox"
                >
                    <p class="mr-4">No, thanks</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputText from "@/components/ui/inputs/InputText";
import _ from "lodash";
import {mapGetters} from "vuex";
import Spinner from "@/components/ui/Spinner";
import {useI18n} from "vue-i18n/dist/vue-i18n";
import InputRadio from "@/components/ui/inputs/InputRadio";
import PurchaseRentGiftCard from "@/views/purchase/rent/PurchaseRentGiftCard";

export default {
    name: "PurchaseRentCoupon",

    components: {PurchaseRentGiftCard, InputRadio, Spinner, InputText},

    data() {
        return {
            couponCode: '',
            loading: false,
            popupShow: false,
            picked: true
        }
    },
    setup() {
        const { t } = useI18n({
            useScope: 'global'
        })

        return { t }
    },
    computed: {
        ...mapGetters({
            couponDiscountAmount: 'getCouponDiscountAmount',
            orderData: 'getOrderData',
            allOrderData: 'getAllOrderData',
            selectedLang: 'getSelectedLanguage',
            insuranceStatus: 'getInsuranceStatus',
        }),

        styleInsuranceButton() {
            let classes = `border-2 px-4 py-4 rounded-xl border-sky-300 flex cursor-pointer hover:bg-sky-300 hover:text-white`;

            if (this.orderData.insurance_status) {
                classes += ` bg-sky-300 text-white`;
            } else {
                classes += ` bg-white text-gray-800`;
            }

            return classes;
        },
        styleCancelButton() {
            let classes = `border-2 px-4 py-4 rounded-xl border-sky-300 flex cursor-pointer hover:bg-sky-300 hover:text-white`;

            if (this.insuranceStatus) {
                classes += ` bg-sky-300 text-white`;
            } else {
                classes += ` bg-white text-gray-800`;
            }

            return classes;
        }
    },

    created() {
        if (this.orderData && this.orderData.coupon_code) {
            this.couponCode = this.orderData.coupon_code;
        }

        let siteUrl = window.location.hostname
        let regex = /^([a-z0-9]+)./gi
        let url = regex.exec(siteUrl)

        if(url[0] === 'lighthouse.') {
            this.$axios.get(`/coupons/lighthouse/getByCode`)
                .then(res => {
                    let coupon = res.data;
                    let orderData = _.cloneDeep(this.orderData);
                    orderData.coupon_id = coupon.id;
                    orderData.coupon_api_id = coupon.coupon_api_id;
                    orderData.coupon_code = coupon.coupon_name;
                    orderData.coupon_type = coupon.discount_type;
                    orderData.coupon_amount = coupon.country_id && coupon.country_id === order.country_id ? coupon.discount_amount : !coupon.country_id || coupon.name ? coupon.discount_amount : 0;
                    orderData.coupon_country_id = coupon.country_id;
                    this.$store.dispatch('setOrderData', orderData);
                    this.loading = false;
                })
                .catch(() => {
                    let orderData = _.cloneDeep(this.orderData);
                    this.$store.dispatch('clearOrderData', 'coupon');
                    this.$store.dispatch('setOrderData', orderData);
                    this.loading = false;
                });
            this.couponCode = 'lighthouse'
        } else if(url[0] === 'sakura-') {
            this.$axios.get(`/coupons/lighthouse/getByCode`)
                .then(res => {
                    let coupon = res.data;
                    let orderData = _.cloneDeep(this.orderData);
                    orderData.coupon_id = coupon.id;
                    orderData.coupon_api_id = coupon.coupon_api_id;
                    orderData.coupon_code = coupon.coupon_name;
                    orderData.coupon_type = coupon.discount_type;
                    orderData.coupon_amount = coupon.country_id && coupon.country_id === order.country_id ? coupon.discount_amount : !coupon.country_id || coupon.name ? coupon.discount_amount : 0;
                    orderData.coupon_country_id = coupon.country_id;
                    this.$store.dispatch('setOrderData', orderData);
                    this.loading = false;
                })
                .catch(() => {
                    let orderData = _.cloneDeep(this.orderData);
                    this.$store.dispatch('clearOrderData', 'coupon');
                    this.$store.dispatch('setOrderData', orderData);
                    this.loading = false;
                });
            this.couponCode = 'sakura01'
        }

    },

    methods: {
        input: _.debounce(function (e) {
            this.loading = true;
            if (e && e.target.value) {
                this.$axios.get(`/coupons/${e.target.value}/getByCode`)
                    .then(res => {
                        let coupon = res.data[0];
                        let orderData = _.cloneDeep(this.allOrderData);

                        if (coupon.coupon_name === 'thankyou50' || coupon.coupon_name === 'jcorp50') {
                            let findCoupon = res.data.find(item => {
                                return orderData[0].country_id === item.country_id;
                            })

                            if (findCoupon) {
                                coupon = findCoupon;
                            }
                        }

                        orderData.forEach((order, i) => {
                            this.$store.dispatch('setDestinationIndex', i)

                            const data = {
                                coupon_id: coupon.id,
                                coupon_api_id: coupon.coupon_api_id,
                                coupon_code: coupon.coupon_name,
                                coupon_type: coupon.discount_type,
                                coupon_amount: coupon.country_id && coupon.country_id === order.country_id ? coupon.discount_amount : !coupon.country_id || coupon.name ? coupon.discount_amount : 0,
                                coupon_country_id: coupon.country_id,
                            }
                            this.$store.dispatch('setSameOrderData', { destinationIndex: i, data });
                        })

                        this.loading = false;
                    })
                    .catch(() => {
                        let orderData = _.cloneDeep(this.allOrderData);
                        orderData.forEach((order, i) => {
                            this.$store.dispatch('setDestinationIndex', i)

                            const data = {
                                coupon_id: null,
                                coupon_api_id: null,
                                coupon_code: null,
                                coupon_type: null,
                                coupon_amount: null,
                                coupon_country_id: null,
                            }
                            this.$store.dispatch('setSameOrderData', { destinationIndex: i, data });
                        })
                        this.loading = false;
                    });
            } else {
                let orderData = _.cloneDeep(this.allOrderData);
                orderData.forEach((order, i) => {
                    this.$store.dispatch('setDestinationIndex', i)

                    const data = {
                        coupon_id: null,
                        coupon_api_id: null,
                        coupon_code: null,
                        coupon_type: null,
                        coupon_amount: null,
                        coupon_country_id: null,
                    }
                    this.$store.dispatch('setSameOrderData', { destinationIndex: i, data });
                })
                this.loading = false;
            }
        }, 300),

        toggleInsurance() {
            let orderData = _.cloneDeep(this.allOrderData);
            orderData.forEach((item, i) => {
                this.$store.dispatch('setDestinationIndex', i)

                const data = {
                    insurance_status: !item.insurance_status,
                };
                this.$store.dispatch('setSameOrderData', { destinationIndex: i, data });
            });
            this.$store.dispatch('setInsuranceStatus', false)
        },

        toggleCheckbox() {
            this.allOrderData.forEach(item => {
                item.insurance_status = false
            })
            this.$store.dispatch('setInsuranceStatus', true)
        }
    }
}
</script>

<style scoped>

</style>