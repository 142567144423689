<template>
    <app-layout>
        <main class="relative">
            <div class="container mx-auto ">
                <div class="text-center py-44">
                    <h1 class="text-center text-4xl sm:text-6xl text-white mb-6 ">
                        <span :class="titleStyles()">{{ titleText }}</span>
                    </h1>
                    <span
                        v-if="this.subdomain !== 'sakura'
                        && this.subdomain !== 'gulliverusa'
                        && this.subdomain !== 'elephanttour'
                        && this.subdomain !== 'lighthouse'
                        && this.subdomain !== 'amnet'
                        && this.subdomain !== 'lax'
                        && this.subdomain !== 'sfo'
                        && this.subdomain !== 'nta'
                        && this.subdomain !== 'kddi'
                        && this.subdomain !== 'hnl'"
                        class="text-center text-4xl sm:text-6xl text-white"
                    >
                          {{ heading && currentLang === 'en' ? heading : heading && currentLang === 'jp' ? heading_jp : $t('subtitle') }}
                    </span>
                    <p class="text-xl text-white my-8">
                        {{ subheading && currentLang === 'en' ? subheading : subheading && currentLang === 'jp' ? subheading_jp : $t('description') }}
                    </p>

                    <purchasing-bar/>
                </div>
            </div>
            <div>
                <div v-if="isSmallScreen && !subdomainClasses[subdomain]" class="home-main-bg"></div>
                <img v-else-if="!isVideoLoaded && !isVideoType" class="blur-image" src="@/assets/png/blur-image.webp" alt="">
                <div v-else-if="isImageType" class="home-main-bg" :style="backgroundImageStyle"></div>
                <div v-else-if="subdomainClasses[subdomain]" :class="subdomainClasses[subdomain]"></div>
                <template v-else>
                    <video v-if="showVideo" class="banner-video" autoplay loop muted @loadeddata="videoLoaded">
                        <source :src="videoSource" type="video/mp4">
                    </video>
                </template>
                <div class="banner-opacity" v-if="isVideoLoaded"></div>
            </div>
        </main>
        <About/>
        <Lax/>

        <div class="container mx-auto px-4">
            <h2 class="text-center mb-8 text-4xl sm:text-5xl blue-text font-bold">{{ $t('countries.popular') }}</h2>

            <popular-countries/>
            <p class="font-medium text-right">Can't find your destination? <span @click="$router.push('purchase-rent')" class="blue-text underline cursor-pointer">Click here</span>
            </p>
            <div class="py-4">
                <Button
                    :text="$t('buttons.rent')"
                    color="none"
                    size="lg"
                    @click="$router.push('purchase-rent')"
                    center="center"
                >
                    <img src="@/assets/svg/arrow-right.svg" alt="">
                </Button>
            </div>
        </div>
        <Location/>
        <Reviews/>
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";

import About from "@/views/home-pages/About"
import Countries from "./home-pages/Countries";
import OperatingCountries from "./home-pages/OperatingCountries";
import Steps from "@/views/home-pages/Steps";
import Reviews from "./home-pages/Reviews";
import Location from "./home-pages/Location";
import Contacts from "./home-pages/Contacts";
import PurchasingBar from "@/views/purchase/_modules/purchasing-bar/PurchasingBar";
import Lax from "./home-pages/Lax";
import PopularCountries from "@/views/purchase/_modules/popular-countries/PopularCountries";
import Button from "@/components/ui/Button";
import {useI18n} from "vue-i18n/dist/vue-i18n";
import {mapGetters} from "vuex";

export default {
    name: "Home.vue",
    components: {
        Button,
        PopularCountries,
        Lax,
        PurchasingBar,
        Contacts, Location, Reviews, Steps, OperatingCountries, Countries, About, AppLayout,
    },
    data() {
        return {
            subdomain: null,
            titleText: '',
            currentLang: 'en',
            serverUrl: 'https://server.skygowifi.com',
            current_img: '',
            isVideoLoaded: false,
            screenWidth: window.innerWidth,
            heading: null,
            subheading: null,
            subheading_jp: null,
            heading_jp: null,
        }
    },
    setup() {
        const {t} = useI18n({
            useScope: 'global'
        })

        return {t}
    },
    computed: {
        getSubdomain() {
            let siteUrl = window.location.hostname
            let regex = /^([a-z0-9]+)./gi
            let res = regex.exec(siteUrl)
            return res[0]
        },
        ...mapGetters({
            selectedLang: 'getSelectedLanguage',
        }),
        isSmallScreen() {
            return this.screenWidth < 640;
        },
        isVideoType() {
            return this.current_img && (this.current_img.includes('.mp4') || this.current_img.includes('.mov') || this.current_img.includes('.avi'));
        },
        isImageType() {
            return this.current_img && !this.isVideoType;
        },
        backgroundImageStyle() {
            return this.current_img ? { backgroundImage: `url(${this.serverUrl}/storage/${this.current_img})` } : '';
        },
        videoSource() {
            return `${this.serverUrl}/storage/${this.current_img}`;
        },
        showVideo() {
            return this.isVideoLoaded && this.isVideoType;
        },
        subdomainClasses() {
            return {
                gulliverusa: 'main-bg-gulliver',
                sakura: 'main-bg-sakura',
                elephanttour: 'main-bg-elephant',
                amnet: 'main-bg-amnet',
                nta: 'main-bg-nta',
                lighthouse: 'main-bg-lighthouse',
                kddi: 'main-bg-kddi'
                // Добавьте другие subdomain и их классы по мере необходимости
            };
        }
    },
    created() {
        window.addEventListener('resize', this.updateScreenWidth);
        this.loadImage()

        this.$axios.get('/headings/get-one')
            .then((res) => {
                this.heading = res.data.heading
                this.subheading = res.data.subheading
                this.subheading_jp = res.data.subheading_jp
                this.heading_jp = res.data.heading_jp
            })
        if (this.getSubdomain === 'skygowifi.') {
            return null
        } else {
            this.subdomain = this.getSubdomain.slice(0, -1)
        }

        if (this.subdomain === 'elephanttour') {
            this.titleText = 'Skygo WiFi for Elephant tour Customer'
        } else if (this.subdomain === 'gulliverusa') {
            this.titleText = 'Skygo WiFi for Gulliver Customer'
        } else if (this.subdomain === 'sakura') {
            this.titleText = 'Skygo WiFi for Sakura Rent a Car Customer'
        } else if (this.subdomain === 'lighthouse') {
            this.titleText = 'Skygo WiFi for Lighthouse Reader'
        } else if (this.subdomain === 'nta') {
            this.titleText = 'Skygo WiFi for Nippon Travel Agency Customer'
        } else if (this.subdomain === 'amnet') {
            this.titleText = 'Skygo WiFi for Amnet Customer'
        } else if (this.subdomain === 'lax') {
            this.titleText = 'Los Angeles Airport Wifi Rental'
        } else if (this.subdomain === 'sfo') {
            this.titleText = 'San Francisco Airport Wifi Rental'
        } else if (this.subdomain === 'hnl') {
            this.titleText = 'Honolulu Airport Wifi Rental'
        } else if (this.subdomain === 'kddi') {
            this.titleText = 'Skygo WiFi For KDDI Customer'
        }
    },
    destroyed() {
        window.removeEventListener('resize', this.updateScreenWidth);
    },
    watch: {
        selectedLang(newVal) {
            this.currentLang = newVal
            if(this.currentLang === 'en') {
                if (this.subdomain === 'elephanttour') {
                    this.titleText = 'Skygo WiFi for Elephant tour Customer'
                } else if (this.subdomain === 'gulliverusa') {
                    this.titleText = 'Skygo WiFi for Gulliver Customer'
                } else if (this.subdomain === 'sakura') {
                    this.titleText = 'Skygo WiFi for Sakura Renta Car Customer'
                } else if (this.subdomain === 'lighthouse') {
                    this.titleText = 'Skygo WiFi for Lighthouse Reader'
                } else if (this.subdomain === 'nta') {
                    this.titleText = 'Skygo WiFi for Lighthouse Reader'
                } else if (this.subdomain === 'amnet') {
                    this.titleText = 'Skygo WiFi for Amnet Customer'
                } else if (this.subdomain === 'lax') {
                    this.titleText = 'Los Angeles Airport Wifi Rental'
                } else if (this.subdomain === 'sfo') {
                    this.titleText = 'San Francisco Airport Wifi Rental'
                } else if (this.subdomain === 'hnl') {
                    this.titleText = 'Honolulu Airport Wifi Rental'
                } else if (this.subdomain === 'kddi') {
                    this.titleText = 'Skygo WiFi For Gulliver Customer'
                }
            }
            if(this.currentLang === 'jp') {
                if (this.subdomain === 'elephattour') {
                    this.titleText = 'zxc'
                } else if (this.subdomain === 'gulliverusa') {
                    this.titleText = 'ガリバーのお客様向けSkygo WiFi'
                } else if (this.subdomain === 'sakura') {
                    this.titleText = 'サクラレンタカーのお客様向けSkygo WiFi'
                } else if (this.subdomain === 'lighthouse') {
                    this.titleText = 'ライトハウス読者様向けSkygo WiFi'
                } else if (this.subdomain === 'nta') {
                    this.titleText = '日本旅行のお客様向けSkygo WiFi'
                } else if (this.subdomain === 'amnet') {
                    this.titleText = 'アムネットのお客様向けSkygo WiFi'
                } else if (this.subdomain === 'lax') {
                    this.titleText = 'Los Angeles Airport Wifi Rental'
                } else if (this.subdomain === 'sfo') {
                    this.titleText = 'San Francisco Airport Wifi Rental'
                } else if (this.subdomain === 'hnl') {
                    this.titleText = 'Honolulu Airport Wifi Rental'
                } else if (this.subdomain === 'kddi') {
                    this.titleText = 'KDDIのお客様向けSkygo WiFi'
                }
            }
        }
    },
    methods: {
        updateScreenWidth() {
            this.screenWidth = window.innerWidth;
        },
        videoLoaded() {
            this.isVideoLoaded = true;
        },
        titleStyles() {
            let style = ` `
            if (this.getSubdomain === 'nta.') {
                style += ` uppercase`
            } else {
                style += ` capitalize`
            }
            return style
        },
        loadImage() {
            this.$axios.get('pages/home-page/get-image')
                .then(res => {
                    this.current_img = res.data.image_src
                    if(!res.data.name.includes('mp4')) {
                        const backgroundImage = new Image();
                        backgroundImage.onload = () => {
                            this.isVideoLoaded = true
                        };
                        backgroundImage.src = `${this.serverUrl}/storage/${this.current_img}`
                    } else {
                        this.isVideoLoaded = true
                    }
                })
        }
    }
}
</script>

<style lang="scss">
main {
    .blur-image,
    .banner-video,
    .home-main-bg,
    .main-bg-gulliver,
    .main-bg-sakura,
    .main-bg-elephant,
    .main-bg-amnet,
    .main-bg-nta,
    .main-bg-lighthouse,
    .main-bg-kddi,
    .japan-bg {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        top: 0;
    }
    .blur-image {
        background-color: #f7fcff;
        filter: blur(150px);
    }
    .banner-video {
        object-fit: cover;
        background-color: #f7fcff;
    }
    .banner-opacity {
        z-index: -1;
        background: #35b8f5;
        opacity: 0.3;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute;
    }
    .home-main-bg {
        background-color: #f7fcff;
        background-image: url("../assets/png/blur-image.webp");
    }
    .main-bg-gulliver {
        background-image: url("../assets/png/top-key01.jpg");

    }
    .main-bg-sakura {
        background-image: url("../assets/png/Beach4.jpg");
    }
    .main-bg-elephant {
        background-image: url("../assets/png/eleph.webp");
    }
    .main-bg-amnet {
        background-image: url("../assets/png/amnet.jpg");
    }
    .main-bg-nta {
        background-image: url("../assets/png/nta.webp");
    }
    .main-bg-lighthouse {
        background-image: url("../assets/png/lighthouse-main.webp");
    }
    .main-bg-kddi {
        background-image: url("../assets/png/kddi-bg.jpg");
    }
    .japan-bg {
        background-image: url("../assets/png/japan-bg.jpg");
    }
    h1 {
        font-family: 'Montserrat', sans-serif;
        font-weight: 800;
    }
    span {
        font-family: 'Montserrat', sans-serif;
        font-weight: 800;
    }
    p {
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
    }
    .changed-bg {
        background-size: cover;
        position: absolute;
        opacity: 0.1;
        background-color: rgba(67, 85, 255, 0.56);
        height: 100%;
        width: 100%;
    }
}

</style>