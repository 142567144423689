<template>
    <Modal
        :show="isModalToggled"
    >
        <div class="bg-white rounded-lg max-w-2xl mx-auto p-8 mt-60">
            Austria, Belgium, Bulgaria, Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Gibraltar, Greece, Iceland, Ireland, Italy, Latvia, Lithuania, Luxembourg, Malta, Netherlands, Norway, Poland, Portugal, Romania, Slovakia, Slovenia, Spain, Sweden, United Kingdom
        </div>
    </Modal>
    <div class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4 mb-8 px-2 md:px-0">
        <template v-if="loading">
            <div class="w-full flex justify-center items-center">
                <spinner />
            </div>
        </template>
        <template v-if="!loading && popularCountries" class="relative">
            <div
                v-for="item in popularCountries" :key="item.id"
                class="travel-country-card border-2 border-sky-300 rounded-lg pt-4 pb-2 text-center cursor-pointer hover:bg-sky-300 hover:text-white"
                :class="{'bg-sky-300 text-white': allOrderData.some(orderItem => orderItem.country_id && item.id === orderItem.country_id)}"
                @click="selectPopularCountry(item)"
                @mouseenter="isBoxActive[item.id] = true"
                @mouseleave="isBoxActive[item.id] = false"
            >
                <div @click="showModal" v-if="isBoxActive[item.id] && item.id === 297" class="relative font-semibold">
                    <img class="absolute -top-5 right-1 sm:-top-6 sm:right-2" src="@/assets/svg/Mediamodifier-Design-Template.png" alt="">
                    <p class="absolute -top-3 right-2 sm:right-2.5 max-w-[75px] text-[11px]">See covered countries</p>
                </div>
                <img
                    class="w-20 mx-auto rounded-lg"
                    :src="require('../../../../assets/svg/flags/' + item.img) "
                    :alt="item.name"
                >
                <p class="mt-4 text-lg">
                    {{ item.name !== 'Europe' ? item.name : 'Europe29' }}
                </p>
                <div v-if="isBoxActive[item.id] || allOrderData.some(orderItem => orderItem.country_id && item.id === orderItem.country_id)" class="w-full h-full border-2 border-sky-300 rounded-lg py-2 text-center cursor-pointer bg-sky-300 text-white absolute top-0 left-0">
                    <p class="font-bold text-sm">{{ item.name }} Pricing</p>
                    <div
                        class="grid"
                        :class="item.packages.length > 2 ? 'grid-cols-2' : 'grid-cols-1 items-center h-[75%]'"
                    >
                        <div class="mb-0.5 text-sm" v-for="pack in item.packages">
                            <p class="font-bold">{{pack.name}}</p>
                            <p class="font-semibold">${{pack.price}}/day</p>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import Spinner from "@/components/ui/Spinner";
import _ from "lodash";
import Button from "@/components/ui/Button";
import Modal from "@/components/ui/modal/Modal";

export default {
    name: "PopularCountries",
    components: {Modal, Button, Spinner},
    data() {
        return {
            loading: false,
            isBoxActive: [],
            isModalToggled: false
        }
    },

    computed: {
        ...mapGetters({
            orderData: 'getOrderData',
            popularCountries: 'getPopularCountries',
            selectedLanguage: 'getSelectedLanguage',
            allOrderData: 'getAllOrderData'
        }),
    },

    created() {
        this.loadPopularCountries();
    },

    methods: {
        showModal() {
            this.isModalToggled = !this.isModalToggled
        },
        loadPopularCountries() {
            this.loading = true;
            this.$axios.get(`/countries/popular`, {
                params: {
                    limit: 10
                }
            })
                .then(res => {
                    let siteUrl = window.location.hostname
                    let regex = /^([a-z0-9]+)./gi
                    let url = regex.exec(siteUrl)
                    this.$store.dispatch('setPopularCountries', res.data);
                    this.popularCountries.forEach(country => {
                        if(this.selectedLanguage === 'jp' && country.name_jp) {
                            country.name = country.name_jp
                        }
                    })
                    if(
                        url[0] === 'lighthouse.'
                        || url[0] === 'nta.'
                        || url[0] === 'sakura-'
                        || url[0] === 'elephanttour.'
                        || url[0] === 'gulliverusa.'
                        || url[0] === 'amnet.'
                        || url[0] === 'kddi.'
                    ) {
                        let orderData = _.cloneDeep(this.orderData);
                        orderData.continent_id = this.popularCountries[1].continent.id;
                        orderData.continent_api_id = this.popularCountries[1].continent.api_continent_id;
                        orderData.continent_name = this.popularCountries[1].continent.name;
                        orderData.country_id = this.popularCountries[1].id;
                        orderData.country_api_id = this.popularCountries[1].api_country_id;
                        orderData.country_name = this.popularCountries[1].name;
                        orderData.flag = this.popularCountries[1].img
                        this.$store.dispatch('setOrderData', orderData);

                        this.loadCountryPackages(this.popularCountries[1].id);
                    }
                    if(
                        url[0] === 'lax.'
                        || url[0] === 'sfo.'
                        || url[0] === 'hnl.'
                    ) {
                        let orderData = _.cloneDeep(this.orderData);
                        orderData.continent_id = this.popularCountries[0].continent.id;
                        orderData.continent_api_id = this.popularCountries[0].continent.api_continent_id;
                        orderData.continent_name = this.popularCountries[0].continent.name;
                        orderData.country_id = this.popularCountries[0].id;
                        orderData.country_api_id = this.popularCountries[0].api_country_id;
                        orderData.country_name = this.popularCountries[0].name;
                        orderData.flag = this.popularCountries[0].img
                        this.$store.dispatch('setOrderData', orderData);

                        this.loadCountryPackages(this.popularCountries[0].id);
                    }
                    this.loading = false;
                });

        },

        selectPopularCountry(country) {
            this.$store.dispatch('setDestinationIndex', 0)
            this.$store.dispatch('clearOrderData', 'package');
            this.$store.dispatch('clearOrderData', 'coupon');
            this.$store.dispatch('clearOrderData', 'insurance');
            this.$store.dispatch('clearOrderData', 'other');

            let orderData = _.cloneDeep(this.orderData);
            orderData.continent_id = country.continent.id;
            orderData.continent_api_id = country.continent.api_continent_id;
            orderData.continent_name = country.continent.name;
            orderData.country_id = country.id;
            orderData.country_api_id = country.api_country_id;
            orderData.country_name = country.name;
            orderData.flag = country.img
            this.$store.dispatch('setOrderData', orderData);

            this.loadCountryPackages(country.id);
        },

        loadCountryPackages(countryId) {
            this.$axios.get(`/countries/${countryId}/get-country-packages`)
                .then(res => {
                    this.$store.dispatch('setCountryPackages', res.data);
                });
        },
    }
}
</script>

<style scoped>

</style>