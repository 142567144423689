<template>
    <div class="mb-0 md:mb-16 px-0 md:px-0 max-w-3xl">
        <template v-if="loading">
            <div class="flex justify-center">
                <spinner/>
            </div>
        </template>

        <div class="relative" v-if="!loading">
            <p class="w-full text-3xl font-semibold mb-8 text-left text-black">
                {{ $t('order.pickup_or_delivery') }}
            </p>
            <div v-if="isDeliveryActive && !isReceiveActive" class="absolute top-0 border-sky-300 border px-4 py-2 rounded-lg bg-white mb-4 text-md">
                Delivery option is unavailable
            </div>
            <div class="w-full mb-4 flex">
                <input-radio
                    name="delivery-or-pickup"
                    :label="$t('order.deliver')"
                    class="mr-4"
                    value="delivery"
                    :checked="shipmentType === 'delivery'"
                    v-model="shipmentType"
                    :disable="!isReceiveActive"
                    @mouseenter="isDeliveryActive = true"
                    @mouseleave="isDeliveryActive = false"
                />

                <input-radio
                    name="delivery-or-pickup"
                    :label="$t('order.pickup')"
                    value="pickup"
                    :checked="shipmentType === 'pickup'"
                    v-model="shipmentType"
                />
            </div>
            <form
                v-if="shipmentType === 'delivery'"
                class="max-w-md w-full block"
            >
                <div class="border-sky-300 border px-4 py-2 rounded-lg bg-white mb-4 text-md">
                    You can expect to receive the device on {{ formattedDateFromToday  }}
                </div>
                <input-select
                    :label="$t('order.ship_type')"
                    class="mb-2"
                    :placeholder="$t('order.ship_type')"
                    :options="shipmentTypes"
                    select-property-name="name"
                    v-model="deliveryForm.type"
                    @selected="selectShipmentType"
                />

                <div
                    v-if="orderData.shipping_type_description"
                    class="px-4 py-2 border-2 border-sky-300 rounded-xl mb-6"
                >
                    <p
                        class="text-red-500"
                        v-html="orderData.shipping_type_description"
                    >

                    </p>
                </div>

                <input-text
                    class="pt-4"
                    :label="$t('order.address')"
                    v-model="deliveryForm.address"
                    :placeholder="$t('order.address')"
                />

                <input-text
                    :label="$t('order.city')"
                    v-model="deliveryForm.city"
                    :placeholder="$t('order.city')"
                />

                <div class="flex gap-2 flex-wrap sm:flex-nowrap">
                    <input-text
                        :label="$t('order.country')"
                        :placeholder="$t('order.country')"
                        v-model="deliveryForm.country"
                        disable
                    />
                    <input-select
                        :label="$t('order.state')"
                        :placeholder="$t('order.state')"
                        select-property-name="name"
                        :options="usaStates"
                        v-model="deliveryForm.state"
                    />
                    <input-text
                        :label="$t('order.zip')"
                        v-model="deliveryForm.zip_code"
                        :placeholder="$t('order.zip')"
                    />
                </div>
            </form>

            <form
                v-if="shipmentType === 'pickup'"
                class="block max-w-md 1/2"
            >
                <input-select
                    :label="$t('order.select_loc')"
                    :placeholder="$t('order.location')"
                    select-property-name="name"
                    :options="pickupLocations"
                    v-model="pickupForm.location"
                    @selected="selectPickupLocation"
                />

                <div class="mb-4">
                    <v-date-picker
                        :mode="pickupMode"
                        :min-date="5 <= getDiff ? getFromDate.setDate(getFromDate.getDate() - minusDates) : new Date()"
                        :max-date="orderData.from_date"
                        v-model="pickupForm.date"
                        :rules="rules"
                        :disabled-dates="allOrderData[0] && (allOrderData[0].pickup_location_api_id === 364 || allOrderData[0].pickup_location_api_id === 377 || allOrderData[0].pickup_location_api_id === 373) ? disabledDates : null"
                    >
                        <template v-slot="{ inputValue, inputEvents }">
                            <label class="block mb-2 text-sm">{{ $t('order.pickup_date') }}</label>
                            <input
                                class="w-full px-4 py-2 border-sky-300 border-2 rounded-xl focus:outline-none focus:border-sky-300"
                                :value="inputValue"
                                v-on="inputEvents"
                                :placeholder="$t('order.add_date')"
                            />
                        </template>
                    </v-date-picker>
                </div>
                <div
                    v-if="orderData.pickup_time_note"
                    class="px-4 py-2 border-2 border-sky-300 rounded-xl text-xl"
                    v-html="orderData.pickup_time_note"
                ></div>
<!--                <div class="w-full mb-4 flex mt-8">-->
<!--                    <input-radio-->
<!--                        name="drop-or-mail"-->
<!--                        label="Drop off location"-->
<!--                        class="mr-4"-->
<!--                        value="drop"-->
<!--                        :checked="returnType === 'drop'"-->
<!--                        v-model="returnType"-->
<!--                        @change="chooseReturnType(returnType)"-->
<!--                    />-->

<!--                    <input-radio-->
<!--                        name="drop-or-mail"-->
<!--                        label="Return by mail"-->
<!--                        value="mail"-->
<!--                        :checked="returnType === 'mail'"-->
<!--                        v-model="returnType"-->
<!--                        @change="chooseReturnType(returnType)"-->
<!--                    />-->
<!--                </div>-->
<!--                <div v-if="returnType === 'mail'" class="px-4 py-2 border-2 border-sky-300 rounded-xl text-xl">-->
<!--                    <p class="text-sm">- Return shipping label will be sent to your email</p>-->
<!--                    <p class="text-sm">- Shipping bag will be provided from pickup location</p>-->
<!--                    <p class="text-sm">- 15$ shipping fee will be applied</p>-->
<!--                </div>-->
                <div class="max-w-md w-full block" v-if="returnType === 'mail'">
                    <h2 class="mt-4 text-xl">Return from address</h2>
                    <input-text
                        class="mt-4"
                        :label="$t('order.address')"
                        v-model="returnForm.address"
                        :placeholder="$t('order.address')"
                    />

                    <input-text
                        :label="$t('order.city')"
                        v-model="returnForm.city"
                        :placeholder="$t('order.city')"
                    />

                    <div class="flex gap-2 flex-wrap sm:flex-nowrap">
                        <input-text
                            :label="$t('order.country')"
                            :placeholder="$t('order.country')"
                            v-model="returnForm.country"
                            disable
                        />
                        <input-select
                            :label="$t('order.state')"
                            :placeholder="$t('order.state')"
                            select-property-name="name"
                            :options="usaStates"
                            v-model="returnForm.state"
                        />
                        <input-text
                            :label="$t('order.zip')"
                            v-model="returnForm.zip_code"
                            :placeholder="$t('order.zip')"
                        />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import InputSelect from "@/components/ui/inputs/InputSelect";
import {mapGetters} from "vuex";
import _ from "lodash";
import Spinner from "@/components/ui/Spinner";
import InputText from "../../../components/ui/inputs/InputText";
import InputRadio from "@/components/ui/inputs/InputRadio";
import {useI18n} from "vue-i18n/dist/vue-i18n";
import { ref } from "vue";
import moment from "moment-timezone";
// import moment from "moment";

export default {
    name: "PurchaseRentPickup",

    components: {InputRadio, InputText, Spinner, InputSelect},

    data() {
        return {
            shipmentType: 'pickup',
            returnType: 'drop',
            minDate: new Date(Date.now()),
            weekends: false,
            sunday: false,
            loading: false,
            date: null,
            deliveryForm: {
                type: null,
                address: '',
                city: '',
                country: 'USA',
                state: '',
                zip_code: '',
            },
            returnForm: {
                type: null,
                address: '',
                city: '',
                country: 'USA',
                state: '',
                zip_code: '',
            },
            pickupForm: {
                location: null,
                date: null,
                time: null
            },
            minusDates: 4,
            pickupMode: 'dateTime',
            todayDate: new Date(),
            isDeliveryActive: false,
            disabledDates: [
                {
                    repeat: {
                        weekdays: [3, 4],
                    }
                }
            ],
        }
    },
    setup() {
        const { t } = useI18n({
            useScope: 'global'
        })

        const rules = ref({
            minutes: { interval: 30 },
        });

        return { t, rules }
    },
    computed: {
        ...mapGetters({
            usaStates: 'getUsaStates',
            shipmentTypes: 'getShipmentTypes',
            pickupLocations: 'getPickupLocations',
            orderData: 'getOrderData',
            allOrderData: 'getAllOrderData',
            totalAmount: 'getOrderTotalAmount',
        }),
        getSubdomain() {
            let siteUrl = window.location.hostname
            let regex = /^([a-z0-9]+)./gi
            let res = regex.exec(siteUrl)
            return res[0]
        },
        getFromDate() {
            return _.cloneDeep(this.orderData.from_date)
        },
        getDiff() {
            let start = _.cloneDeep(this.orderData.from_date) || new Date()
            let today = new Date()
            start.setHours(0, 0, 0, 0);
            today.setHours(0, 0, 0, 0);

            let timeDiff = Math.abs(today.getTime() - start.getTime());
            let result = Math.ceil(timeDiff / (1000 * 3600 * 24))

            return result;
        },

        isReceiveActive() {
            let currentDateTime = moment().local();

            let newYorkDateTime = moment(currentDateTime).tz("America/New_York");
            let isMore14HoursInNY = newYorkDateTime.hour() > 14
                || (newYorkDateTime.hour() === 14
                    && newYorkDateTime.minute() > 0)
            let selectedDate = moment(this.getFromDate);
            let newYorkDate = newYorkDateTime.set({hour:0,minute:0,second:0,millisecond:0})
            let daysDifference = selectedDate.diff(newYorkDate, "days")
            if((daysDifference === 2 && isMore14HoursInNY) || daysDifference >= 3) {
                return true
            }
            this.shipmentType = 'pickup';
            return false
        },
        formattedDateFromToday() {
            if(this.getDiff >= 11) {
                const twoDaysAgo = new Date(this.getFromDate);
                twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);

                const year = twoDaysAgo.getFullYear();
                const month = String(twoDaysAgo.getMonth() + 1).padStart(2, '0');
                const day = String(twoDaysAgo.getDate()).padStart(2, '0');

                return `${month}/${day}/${year}`;
            } else {
                const twoDaysAgo = new Date();
                twoDaysAgo.setDate(twoDaysAgo.getDate() + 2);

                const year = twoDaysAgo.getFullYear();
                const month = String(twoDaysAgo.getMonth() + 1).padStart(2, '0');
                const day = String(twoDaysAgo.getDate()).padStart(2, '0');

                return `${month}/${day}/${year}`;
            }
        }
    },

    watch: {
        deliveryForm: {
            handler() {
                let orderData = _.cloneDeep(this.allOrderData);

                orderData.forEach((item, i) => {
                    this.$store.dispatch('setDestinationIndex', i)

                    const data = {
                        address: this.deliveryForm.address,
                        city: this.deliveryForm.city,
                        country: this.deliveryForm.country,
                        state: this.deliveryForm.state,
                        zip_code: this.deliveryForm.zip_code
                    };
                    this.$store.dispatch('setSameOrderData', { destinationIndex: i, data });
                });
            },
            deep: true
        },
        returnForm: {
            handler() {
                let orderData = _.cloneDeep(this.allOrderData);

                orderData.forEach((item, i) => {
                    this.$store.dispatch('setDestinationIndex', i)

                    const data = {
                        return_address: this.returnForm.address,
                        return_city: this.returnForm.city,
                        return_country: this.returnForm.country,
                        return_state: this.returnForm.state,
                        return_zip_code: this.returnForm.zip_code,
                    };
                    this.$store.dispatch('setSameOrderData', { destinationIndex: i, data });
                });
            },
            deep: true
        },
        pickupForm: {
            handler() {
                let orderData = _.cloneDeep(this.allOrderData);
                orderData.forEach((item, i) => {
                    this.$store.dispatch('setDestinationIndex', i)

                    const data = {
                        pickup_dt_tm: this.pickupForm.date,
                        pickup_time: this.pickupForm.date,
                    };
                    this.$store.dispatch('setSameOrderData', { destinationIndex: i, data });
                });

                if (orderData[0].pickup_location_id === 2 || orderData[0].pickup_location_id === 3) {
                    this.weekends = true
                    this.sunday = false
                } else {
                    this.weekends = false
                    this.sunday = false
                }
                if (orderData[0].pickup_location_api_id === 354 && this.getDiff <= 5) {
                    this.minusDates = 5
                } else if(orderData[0].pickup_location_api_id === 354) {
                    this.minusDates = 6
                } else {
                    this.minusDates = 4
                }
                if (orderData[0] && orderData[0].pickup_location_api_id === 354 || orderData[0].pickup_location_api_id === 353 || orderData[0].pickup_location_api_id === 365) {
                    this.pickupMode = 'date'
                } else {
                    this.pickupMode = 'dateTime'
                }
                if (orderData[0] && orderData[0].pickup_location_api_id === 364) {
                    this.disabledDates.splice(0);
                    this.disabledDates.push({
                        repeat: {
                            weekdays: [3, 4]
                        }
                    });
                } else if(orderData[0] && orderData[0].pickup_location_api_id === 377) {
                    this.disabledDates.splice(0);
                    this.disabledDates.push({
                        repeat: {
                            weekdays: [1, 3, 5]
                        }
                    });
                } else if(orderData[0] && orderData[0].pickup_location_api_id === 373) {
                    this.disabledDates.splice(0);
                    this.disabledDates.push({
                        repeat: {
                            weekdays: [3]
                        }
                    });
                } else {
                    this.disabledDates.splice(0);
                }
            },
            deep: true
        },
        shipmentType(value) {
            switch (value) {
                case 'delivery':

                    let orderData = _.cloneDeep(this.allOrderData);

                    orderData.forEach((item, i) => {
                        this.$store.dispatch('setDestinationIndex', i)
                        const data = {
                            return_by_mail: false,
                        };
                        this.$store.dispatch('setSameOrderData', { destinationIndex: i, data });
                    });
                    this.orderData.pickup_type = 'Delivery'
                    this.$store.dispatch('clearOrderData', 'pickup')
                        .then(() => {
                            this.pickupForm.location = null;
                            this.pickupForm.date = null;
                        });
                    break;
                case 'pickup':

                    this.returnType = "drop"
                    this.orderData.pickup_type = 'Pickup'
                    this.$store.dispatch('clearOrderData', 'address');
                    this.$store.dispatch('clearOrderData', 'shipping')
                    this.$store.dispatch('clearOrderData', 'return')
                        .then(() => {
                            this.deliveryForm.type = null;
                            this.deliveryForm.address = null;
                            this.deliveryForm.city = null;
                            this.deliveryForm.state = null;
                            this.deliveryForm.zip_code = null;
                            this.returnForm.type = null;
                            this.returnForm.address = null;
                            this.returnForm.city = null;
                            this.returnForm.state = null;
                            this.returnForm.zip_code = null;
                        });
                    break;
            }
        },
    },

    created() {
        this.loadShipmentTypes();
        this.loadPickupLocations();

        if(this.getSubdomain === 'sakura-' || this.getSubdomain === 'elephanttour.') {
            this.shipmentType = 'pickup'
        }

    },

    methods: {
        chooseReturnType(type) {
            let orderData = _.cloneDeep(this.allOrderData);
            if(type === 'mail') {
                orderData.forEach((item, i) => {
                    this.$store.dispatch('setDestinationIndex', i)
                    const data = {
                        return_by_mail: true,
                    };
                    this.$store.dispatch('setSameOrderData', { destinationIndex: i, data });
                });
            } else {
                orderData.forEach((item, i) => {
                    this.$store.dispatch('setDestinationIndex', i)
                    const data = {
                        return_by_mail: false,
                    };
                    this.$store.dispatch('setSameOrderData', { destinationIndex: i, data });
                });
            }
        },
        isDateInRange(start, end) {
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            const startDate = new Date(start);
            startDate.setHours(0, 0, 0, 0);
            const endDate = new Date(end);
            endDate.setHours(0, 0, 0, 0);
            return currentDate >= startDate && currentDate <= endDate;
        },
        loadShipmentTypes() {
            this.$axios.get(`/shipping/getTypes`)
                .then(res => {
                    this.$store.dispatch('setShipmentTypes', res.data);
                });
        },

        loadPickupLocations() {
            this.$axios.get(`/pickup/getLocations`)
                .then(res => {
                    let hiddenElementData = res.data.filter(el => el.name !== 'Corporate')
                    this.$store.dispatch('setPickupLocations', hiddenElementData);
                    if(this.getSubdomain === 'sakura-') {
                        this.pickupForm.location = 'Sakura Rent-a-car'
                        this.selectPickupLocation(this.pickupLocations[3])
                    } else if(this.getSubdomain === 'elephanttour.') {
                        this.pickupForm.location = 'Downtown LA (Elephant Tour) Office'
                        this.selectPickupLocation(this.pickupLocations[2])
                    } else if(this.getSubdomain === 'lax.') {
                        this.pickupForm.location = 'Los Angeles Airport'
                        this.selectPickupLocation(this.pickupLocations[0])
                    } else if(this.getSubdomain === 'sfo.') {
                        this.pickupForm.location = 'San Francisco Airport'
                        this.selectPickupLocation(this.pickupLocations[4])
                    } else if(this.getSubdomain === 'hnl.') {
                        this.pickupForm.location = 'Honolulu'
                        this.selectPickupLocation(this.pickupLocations[5])
                    }
                });
        },

        selectShipmentType(type) {
            let orderData = _.cloneDeep(this.allOrderData);
            if (this.shipmentType === 'delivery') {
                orderData.forEach((item, i) => {
                    this.$store.dispatch('setDestinationIndex', i)
                    const data = {
                        shipping_type_id: type.id,
                        shipping_type_api_id: type.api_shipping_type_id,
                        shipping_type_name: type.name,
                        shipping_type_description: type.description,
                        shipping_type_charges: i === 0 ? type.charges : 0,
                        is_delivery: true,
                    };
                    this.$store.dispatch('setSameOrderData', { destinationIndex: i, data });
                });

            }
        },

        selectPickupLocation(location) {
            let orderData = _.cloneDeep(this.allOrderData);
            if (this.shipmentType === 'pickup') {
                orderData.forEach((item, i) => {
                    this.$store.dispatch('setDestinationIndex', i)

                    const data = {
                        pickup_location_id: location.id,
                        pickup_location_api_id: location.api_pickup_location_id,
                        pickup_location_name: location.name,
                        pickup_time_note: location.time_note,
                        is_delivery: false,
                    };
                    this.$store.dispatch('setSameOrderData', { destinationIndex: i, data });
                });

            }
        },
    },
}
</script>

<style scoped>

</style>