<template>
    <app-layout>
        <hr class="w-12 main-bg h-1 mb-4 mx-auto mt-16">
        <h1 class="blue-text text-5xl font-bold text-center mb-12 px-4">{{ $t('coverage_map.title') }}</h1>
        <div class="container mx-auto">
            <img class="mx-auto shadow-2xl" src="@/assets/png/prices-cov-big.png" alt="">
            <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-16">
                <div class="shadow-2xl bg-white p-4" v-for="countryPackages in packages">
                    <h2 class="text-xl font-bold">{{countryPackages.name}}</h2>
                    <p class="inline-flex mr-2">{{countryPackages.countries}},</p>
                </div>
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import {useI18n} from "vue-i18n/dist/vue-i18n";
export default {
    name: "CoverageMap",
    components: {AppLayout},
    setup() {
        const {t} = useI18n({
            useScope: 'global'
        })

        return {t}
    },
    data() {
        return {
            packages: [
                {
                    id: 1, name: this.$t("coverage_map.continents.africa.title"), countries: this.$t("coverage_map.continents.africa.countries")
                },
                {
                    id: 2, name: this.$t("coverage_map.continents.america.title"), countries: this.$t("coverage_map.continents.america.countries")
                },
                {
                    id: 3, name: this.$t("coverage_map.continents.asia.title"), countries: this.$t("coverage_map.continents.asia.countries")
                },
                {
                    id: 4, name: this.$t("coverage_map.continents.europe.title"), countries: this.$t("coverage_map.continents.europe.countries")
                },
                {
                    id: 5, name: this.$t("coverage_map.continents.oceania.title"), countries: this.$t("coverage_map.continents.oceania.countries")
                },
            ]
        }
    }
}
</script>

<style scoped>

</style>